<template>
    <MODAL
        :skin="skin"
        :width="600"
        :visible="visible"
        :class="namespaceClass('excel-rtd')"
        :title="initedLabels.excel"
    >
        <button
            slot="header-actions"
            class="mds-button mds-button--icon-only"
            @click="closeExcelRTD"
        >
            <svg class="mds-icon mds-button__icon mds-button__icon--left">
                <use :xlink:href="mdsIcon('remove')">
                    <title>Close</title>
                </use>
            </svg>
        </button>
        <div :class="namespaceClass('excel-rtd-content')" slot="content">
            <h6>Morningstar Excel RTD</h6>
            <p>
                Morningstar Excel RTD Add-In allows user to retrieve market data
                into Microsoft Excel to perform additional calculation,
                formatting, charting or analysis. Data will be dynamically
                populated in the spreadsheet after user is verified.
            </p>
            <div :class="namespaceClass('download')">
                <div :class="namespaceClass('download__name')">
                    Latest Version <span v-text="excelRTD.version"></span>
                </div>
                <span
                    ><button
                        class="mds-button  mds-button--secondary"
                        type="button"
                        @click="downLoadRTD"
                    >
                        Download
                    </button></span
                >
            </div>
            <h6 :class="namespaceClass('rtd-desc-title')">
                Have I installed the Add-In?
            </h6>
            <p>
                If you see the following ‘Morningstar RTD’ tab in your Excel
                header, you have already installed the program.
            </p>
            <img src="../../assets/images/Excel-RTD.png" />
            <h6 :class="namespaceClass('rtd-desc-title')">
                How to download and install?
            </h6>
            <P>
                Click on the above ‘Download’ link to install the latest version
                of Morningstar Excel RTD Add-In.
            </P>
            <ol :class="namespaceClass('download-step')">
                <li>
                    Locate 'Excel RTD Setup.exe' file in the designated browser
                    download folder
                </li>
                <li>
                    Run the executable file
                </li>
                <li>
                    Follow setup wizard instruction to complete installation
                </li>
            </ol>
            <div
                :class="namespaceClass('download')"
                v-if="excelRTD.installationGuide"
            >
                <div :class="namespaceClass('download__name')">
                    Morningstar Excel RTD Installation Guide
                </div>
                <span
                    ><button
                        class="mds-button  mds-button&#45;&#45;secondary"
                        type="button"
                        @click="downloadGuide"
                    >
                        Download
                    </button></span
                >
            </div>
        </div>
    </MODAL>
</template>
<script>
import labels from '../../assets/metadata/label.json';
import { mapState, mapGetters, mapActions } from 'vuex';
import MODAL from '../ui/modal';
import { download } from '../../common';

export default {
    name: 'ExcelRTDDownload',
    components: { MODAL },
    data() {
        return {
            visible: true
        };
    },
    created() {
        if (!this.excelRTD.version) {
            this.getExcelRTDConfig();
        }
        this.$eventBus.$on(this.$EVENTS.SHOW_EXCELRTD, () => {
            this.visible = true;
        });
    },
    computed: {
        ...mapGetters('settings', ['skin']),
        ...mapState('application', ['excelRTD']),
        initedLabels() {
            return labels.en;
        }
    },
    methods: {
        ...mapActions('application', ['getExcelRTDConfig']),
        closeExcelRTD() {
            this.visible = false;
        },
        downloadGuide() {
            download(
                `excelRTD/en-us/${this.excelRTD.installationGuide}`,
                this.excelRTD.installationGuide
            );
        },
        downLoadRTD() {
            download(
                `excelRTD/en-us/${this.excelRTD.installation}`,
                this.excelRTD.installation
            );
        }
    }
};
</script>
